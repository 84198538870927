/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@material-ui/core";
import { useLoadSongAfterGettingDuration } from "hooks/use_song_duration";

import { useEffect, useRef, useState } from "react";
import css from "./song_player.module.scss";
import { SongPlayerControls } from "./song_player_controls/song_player_controls";

interface ISongPlayer {
  src: string;
}

export const SongPlayer = ({ src }: ISongPlayer) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [songProgress, setSongProgress] = useState(0);

  const audioRef = useRef(new Audio(src));
  const intervalRef = useRef<ReturnType<typeof setInterval>>();

  const { duration } = useLoadSongAfterGettingDuration(audioRef.current);

  const playButtonHandler = () => {
    duration && setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  }, [isPlaying]);

  useEffect(() => {
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current as any);
    };
  }, []);

  const onScrub = (_: any, value: number | number[]) => {
    clearInterval(intervalRef.current as any);
    audioRef.current.currentTime = value as number;
    setSongProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  const startTimer = () => {
    clearInterval(intervalRef.current as any);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        setIsPlaying(false);
      } else {
        setSongProgress(audioRef.current.currentTime);
      }
      // @ts-ignore
    }, [100]);
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      clearInterval(intervalRef.current as any);
      audioRef.current.pause();
      setIsPlaying(false);
    }
  }, [isPlaying]);

  return duration ? (
    <Box className={css.song_player_wrapper}>
      <SongPlayerControls
        audioUrl={src}
        onScrub={onScrub}
        onScrubEnd={onScrubEnd}
        duration={duration || 0}
        isPlaying={isPlaying}
        playButtonHandler={playButtonHandler}
        songProgress={songProgress}
      />
    </Box>
  ) : null;
};
