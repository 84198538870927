import { useState } from "react";
import { RecorderControlsProps } from "types/recorder";
import cnn from "classnames";
import { Button } from "@material-ui/core";

import css from "./record_button.module.scss";

interface IRecordButton extends RecorderControlsProps {}

export const RecordButton = ({ recorderState, handlers }: IRecordButton) => {
  const { startRecording, saveRecording } = handlers;
  const { initRecording } = recorderState;
  const [recordButtonActive, setRecorderButtonActive] =
    useState<boolean>(false);

  const processClick = () => {
    setRecorderButtonActive(!recordButtonActive);
    if (initRecording) {
      saveRecording();
    } else {
      startRecording();
    }
  };

  const getRecordButtonClassName = () => {
    if (recordButtonActive && !initRecording) {
      return css.icon_recording_inner_loading;
    }
    if (recordButtonActive) {
      return css.icon_recording_inner_active;
    }
    return css.icon_recording_inner_inactive;
  };

  return (
    <Button
      aria-label="recording"
      disabled={recordButtonActive && !initRecording}
      onClick={processClick}
      title={`${initRecording ? "Stop Recording" : "Start Recording"}`}
      disableRipple
    >
      <div className={css.icon_recording_outer}>
        <div
          className={cnn(css.icon_recording_inner, getRecordButtonClassName())}
        />
      </div>
    </Button>
  );
};
