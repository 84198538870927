import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCustomSongAsset } from "redux/songwriting/songwriting_slice";
import { deleteAudioSeed } from "redux/song_seeds/song_seeds_slice";
import { Audio } from "types/recorder";
import { v4 as uuid } from "uuid";

import {
  getAudioSeeds,
  getCurrentSeedType,
} from "redux/song_seeds/song_seed_selector";
import { IAudioSeed } from "redux/song_seeds/song_seed_interfaces";
import { ICustomSongAsset } from "redux/songwriting/songwriting_interfaces";
import { IState } from "../redux/store";

export default function useRecordingsList() {
  const [recordings, setRecordings] = useState<Audio[]>([]);

  const { customSelectedSong } = useSelector(
    (state: IState) => state.songwriting,
  );
  const audioSeeds = useSelector(getAudioSeeds);
  const currentSeedType = useSelector(getCurrentSeedType);
  const { custom_song_assets: songAssets } = customSelectedSong;
  const dispatch = useDispatch();

  useEffect(() => {
    const sortByCreated = (records: IAudioSeed[] | ICustomSongAsset[]) => {
      return [...records].sort((a, b) => {
        const date1 = new Date(a.createdAt);
        const date2 = new Date(b.createdAt);
        return date2.getTime() - date1.getTime();
      });
    };

    const formatRecords = (records: IAudioSeed[] | ICustomSongAsset[]) => {
      return records.map((record) => ({
        key: uuid(),
        audio: record.url,
        assetId: record.id,
        rec_minutes: record.rec_minutes,
        rec_seconds: record.rec_seconds,
        createdAt: record.createdAt,
        ...(record.title && { title: record.title }),
      }));
    };

    if (currentSeedType === "audio") {
      const sortedAudioSeeds = sortByCreated(audioSeeds);

      setRecordings(formatRecords(sortedAudioSeeds));
    } else if (songAssets) {
      const sortedAudioAssets = sortByCreated(songAssets);

      setRecordings(formatRecords(sortedAudioAssets));
    }
  }, [songAssets, currentSeedType, audioSeeds]);

  return {
    recordings,
    deleteAudio: (assetUrl: string) => {
      if (currentSeedType === "audio") {
        dispatch(deleteAudioSeed(assetUrl));
      } else {
        dispatch(deleteCustomSongAsset(assetUrl));
      }
    },
  };
}
