import { RecorderControlsProps } from "types/recorder";
import { RecordButton } from "../record_button/record_button";

import css from "./record_controls.module.scss";

interface IRecordControls extends RecorderControlsProps {}

export const RecordControls = ({
  recorderState,
  handlers,
}: IRecordControls) => {
  const { initRecording, recordingMinutes, recordingSeconds } = recorderState;

  return (
    <div className={css.record_wrapper}>
      {initRecording && (
        <>
          <p className={css.default_title_recording}>New Recording</p>
          <div className={css.recording_time}>
            <span>{recordingMinutes}</span>
            <span>:</span>
            <span>
              {recordingSeconds < 10
                ? `0${recordingSeconds}`
                : recordingSeconds}
            </span>
          </div>
        </>
      )}

      {!initRecording && (
        <p className={css.title_start_recording}>
          Tap the button to start recording
        </p>
      )}
      <RecordButton recorderState={recorderState} handlers={handlers} />
    </div>
  );
};
