import { Spinner } from "components/loading/spinner_circle";
import { isEqual, omit } from "lodash";
import { useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
/* eslint-disable no-nested-ternary */
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  getFilteredLessonsSelector,
  getLessonsHasNextPageSelector,
  getMyLessonsSelector,
  lessonsFetchingSelector,
} from "redux/entities/lessons/lesson_selectors";
import { searchLessons } from "redux/entities/lessons/lessons_slice";
import { fetchUserLessons } from "redux/entities/user_lessons/user_lessons_slice";
import { getSongFilters } from "redux/songs_filter/song_filter_selectors";
import { ALL_SONGS, MY_SONGS } from "routes/route_constants";
import {
  filterLessonsWithoutSongs,
  putFreeSongsFirst,
  sortBySongName,
} from "util/songs_helpers";

import { NewAllSongsPage } from "./new_all_songs_page/new_all_songs_page";
import { NoLessonsFound } from "./no_lessons_found";
import css from "./song.module.scss";
import { SongCard } from "./song_card";
import { SongsToolPanel } from "./songs_tool_panel/songs_tool_panel";

interface ISongList {
  hideToolPanel: boolean;
}

const emptyFilterExample = {
  chords: [],
  chordsExactMatch: false,
  decade: "",
  lessonKeys: [],
  numberOfChords: "",
  search: "",
  skillTypes: [],
  songKeys: [],
};

export const SongList = (props: ISongList) => {
  const [showGrid, setShowGrid] = useState(false);
  const { hideToolPanel } = props;
  const songFilters = useSelector(getSongFilters);
  const lessons = useSelector(getFilteredLessonsSelector);
  let lessonValues = filterLessonsWithoutSongs(Object.values(lessons));
  const userOwnedLessons = useSelector(getMyLessonsSelector);
  const hasNextPage = useSelector(getLessonsHasNextPageSelector);
  const fetching = useSelector(lessonsFetchingSelector);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      pathname === MY_SONGS ||
      !isEqual(emptyFilterExample, omit(songFilters, ["instruments"]))
    ) {
      setShowGrid(true);
    } else {
      setShowGrid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [songFilters, pathname]);

  useEffect(() => {
    dispatch(fetchUserLessons());
  }, [dispatch]);

  if (pathname === MY_SONGS) {
    lessonValues = userOwnedLessons;
  }

  sortBySongName(lessonValues);

  // put free songs at the top of the page
  if (pathname === ALL_SONGS || pathname === "/") {
    lessonValues = putFreeSongsFirst(lessonValues);
  }

  const location = useLocation();
  const history = useHistory();

  const [infiniteRef] = useInfiniteScroll({
    loading: fetching,
    hasNextPage,
    onLoadMore: () => dispatch(searchLessons({ resetLessons: false })),
    delayInMs: 400,
    rootMargin: "0px 0px 150px 0px",
  });

  const EMPTY = 0;
  if (lessonValues.length === EMPTY && location.pathname === MY_SONGS) {
    return (
      <div className={css.no_lessons_container}>
        <div className={css.no_lessons}>
          You haven't started any lessons yet! Go to the&nbsp;
          <button
            type="button"
            className={css.link}
            onClick={() => history.push(ALL_SONGS)}
          >
            All Songs
          </button>
          &nbsp;page and choose your first lesson.
        </div>
      </div>
    );
  }

  return (
    <>
      {!hideToolPanel && <SongsToolPanel showGrid={showGrid} />}
      {showGrid ? (
        lessonValues.length ? (
          <div className={css.grid}>
            {lessonValues.map((lesson) => {
              return <SongCard key={lesson.id} lesson={lesson} />;
            })}
            {hasNextPage && pathname !== MY_SONGS && !fetching && (
              <div className={css.loader_container} ref={infiniteRef}>
                <Spinner
                  skChildClassName="sk-child-purple"
                  skCircleClassName="sk-circle-purple"
                />
              </div>
            )}
          </div>
        ) : (
          <NoLessonsFound />
        )
      ) : (
        <div>
          <NewAllSongsPage lessons={lessonValues} setShowGrid={setShowGrid} />
        </div>
      )}
    </>
  );
};
