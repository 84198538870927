import { useMemo, useState, ChangeEvent, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { totalTimeInHours } from "components/my_journey/my_journey";
import {
  getCourseById,
  getCourseCSVData,
} from "redux/courses/courses_selectors";
import moment, { Moment } from "moment";
import "./datepicker.scss";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker, FocusedInputShape } from "react-dates";

import { setCourseDateFilter } from "redux/courses/courses_slice";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { CUSTOM } from "./course_top";
import css from "./course_summarized_info.module.scss";

export const SEVEN_DAYS = 7;
export const THIRTY_DAYS = 30;
export const FOUR_MONTH = 120;

interface ICourseSummarizedInfo {
  courseId: number;
  startDate: string | null;
  endDate: string | null;
}

interface IDatePickerOnChange {
  startDate: Moment | null;
  endDate: Moment | null;
}

export const CourseSummarizedInfo = (props: ICourseSummarizedInfo) => {
  const dispatch = useDispatch();
  const { courseId, startDate, endDate } = props;

  const courseData = useSelector(getCourseById(courseId));
  const sumObj = {
    students: 0,
    practice: 0,
    lessons: 0,
    skills: 0,
  };
  const csvData = useSelector(getCourseCSVData);
  csvData.forEach((student) => {
    sumObj.students++;

    sumObj.practice += student.practice_time_mins || 0;
    sumObj.lessons += student.lessons_number || 0;
    sumObj.skills += student.skills_number || 0;
  });

  const time = totalTimeInHours(sumObj.practice);
  const today = useMemo(() => moment(), []);

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null,
  );

  const courseCreationDateDiff =
    today.diff(moment(courseData?.createdAt), "days") + 1;

  const [chosenItem, setChosenItem] = useState<unknown>(courseCreationDateDiff);

  const onDatePickerChange = (params: IDatePickerOnChange) => {
    const { startDate, endDate } = params;
    dispatch(
      setCourseDateFilter({
        startDate: startDate?.toISOString() || null,
        endDate: endDate?.toISOString() || null,
      }),
    );
  };

  const onSelectChange = (event: ChangeEvent<{ value: unknown }>) => {
    let newDates = {};
    if (event.target.value === CUSTOM) {
      newDates = {
        startDate: null,
        endDate: null,
      };
    } else {
      const newStartDate = moment().subtract(
        event.target.value as number,
        "days",
      );
      newDates = {
        startDate: newStartDate?.toISOString(),
      };
    }
    dispatch(setCourseDateFilter(newDates));

    setChosenItem(event.target.value);
  };

  useEffect(() => {
    const initialDate = moment().subtract(courseCreationDateDiff, "days");
    dispatch(
      setCourseDateFilter({
        startDate: initialDate?.toISOString(),
      }),
    );
  }, [courseCreationDateDiff, dispatch]);

  if (!courseData) {
    return null;
  }

  const startDateValue = startDate ? moment(startDate) : null;
  const endDateValue = endDate ? moment(endDate) : null;

  return (
    <div className={css.org_info}>
      <div className={css.top_bar}>
        Class stats from the&nbsp;
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={chosenItem}
          onChange={onSelectChange}
        >
          <MenuItem value={courseCreationDateDiff}>Beginning of time</MenuItem>
          <MenuItem value={SEVEN_DAYS}>Last 7 Days</MenuItem>
          <MenuItem value={THIRTY_DAYS}>Last 30 Days</MenuItem>
          <MenuItem value={FOUR_MONTH}>Last 4 Month</MenuItem>
          <MenuItem value={CUSTOM}>Custom</MenuItem>
        </Select>
        {chosenItem === "CUSTOM" && (
          <DateRangePicker
            onFocusChange={(focusedInputParam) => {
              if (focusedInputParam !== focusedInput) {
                setFocusedInput(focusedInputParam);
              }
            }}
            isOutsideRange={() => false}
            onDatesChange={onDatePickerChange}
            minDate={moment(courseData?.createdAt)}
            startDateId="startDate"
            endDateId="endDate"
            focusedInput={focusedInput}
            startDate={startDateValue}
            endDate={endDateValue}
            regular
            orientation="horizontal"
          />
        )}
      </div>
      <div className={css.org_sum_skills}>
        <SkillCard value={sumObj.students} text="Students" />
        <SkillCard value={sumObj.skills} text="Skills Learned" />
        <SkillCard value={sumObj.lessons} text="Songs Learned" />
        <SkillCard value={time} text="Practice Time" />
      </div>
    </div>
  );
};

interface ISkillCard {
  value: string | number;
  text: string;
}
// eslint-disable-next-line react/no-multi-comp
const SkillCard = (props: ISkillCard) => {
  const { value, text } = props;
  return (
    <div>
      <div className={css.card_value}>{value}</div>
      <div className={css.card_text}>{text}</div>
    </div>
  );
};
