import { calculatePercentage } from "util/math_util";
import { Card } from "../card";
import { ISkillsCard, TRANSITIONS_CARD } from "..";

interface ITransitionsCard extends ISkillsCard {
  transitionsCount: number;
  userTransitionsCount: number;
}

export const TransitionsCard = (props: ITransitionsCard) => {
  const {
    active,
    isOtherActive,
    handleClickOpen,
    handleClickClose,
    children,
    transitionsCount,
    userTransitionsCount,
    profileLinkText = "Manually Adjust Skills",
  } = props;

  return (
    <Card
      measurementValue={userTransitionsCount}
      progress={calculatePercentage(userTransitionsCount, transitionsCount)}
      text="Chord Transitions"
      active={active}
      handleClick={() => handleClickOpen(TRANSITIONS_CARD)}
      handleClickClose={() => handleClickClose()}
      isOtherActive={isOtherActive}
      line
      profileLinkText={profileLinkText}
    >
      {children}
    </Card>
  );
};
