import React, { useState } from "react";

import cnn from "classnames";
import useRecordingsList from "hooks/use_recording_list";

import { Box, IconButton, MenuItem, Typography } from "@material-ui/core";
import { StyledMenu } from "components/songwriting_mobile/styled_menu/styled_menu";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import PauseCircleOutline from "@material-ui/icons/PauseCircleOutline";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import ReplyIcon from "@material-ui/icons/Reply";

import css from "./song_player_controls_buttons.module.scss";

interface ISongPlayersControlsButtons {
  isPlaying: boolean;
  playButtonHandler: () => void;
  audioUrl: string;
}

export const SongPlayersControlsButtons = ({
  isPlaying,
  audioUrl,
  playButtonHandler,
}: ISongPlayersControlsButtons) => {
  const { deleteAudio } = useRecordingsList();

  const [seedAnchorEl, setSeedAnchorEl] = useState<null | HTMLElement>(null);

  const deleteButtonHandler = (e: React.MouseEvent<HTMLElement>) => {
    setSeedAnchorEl(e.currentTarget as HTMLElement);
  };

  const callDeleteAudio = () => {
    deleteAudio(audioUrl);
    setSeedAnchorEl(null);
  };

  return (
    <>
      <div className={css.control_buttons_wrapper}>
        <ReplyIcon
          style={{ visibility: "hidden" }}
          className={cnn(css.icon, css.share_icon)}
        />
        {isPlaying ? (
          <PauseCircleOutline
            onClick={playButtonHandler}
            className={cnn(css.icon, css.control_play_icon)}
          />
        ) : (
          <PlayCircleOutlineIcon
            onClick={playButtonHandler}
            className={cnn(css.icon, css.control_play_icon)}
          />
        )}
        <IconButton onClick={deleteButtonHandler}>
          <DeleteOutlineIcon className={cnn(css.icon, css.trash_icon)} />
        </IconButton>
      </div>
      <StyledMenu
        id="deleteMenu"
        anchorEl={seedAnchorEl}
        keepMounted
        open={Boolean(seedAnchorEl)}
        onClose={() => {
          setSeedAnchorEl(null);
        }}
        vertical={75}
      >
        <MenuItem onClick={callDeleteAudio}>
          <Box display="flex" style={{ gap: "10px" }}>
            <DeleteOutlineIcon />
            <Typography>Delete</Typography>
          </Box>
        </MenuItem>
      </StyledMenu>
    </>
  );
};
