import { useSelector } from "react-redux";
import useRecorder from "hooks/use_recorder";
import { getCurrentSeedType } from "redux/song_seeds/song_seed_selector";
import { getUsingMobileAppFlag } from "redux/songwriting/songwriting_selectors";
import { RecordsList } from "./records_list/records_list";
import css from "./record_bar.module.scss";
import { RecordControls } from "./record_controls/record_controls";

export const RecordBar = () => {
  const { recorderState, ...handlers } = useRecorder();
  const currentSeedType = useSelector(getCurrentSeedType);
  const mobile = useSelector(getUsingMobileAppFlag);

  const getRecordBarWrapperClassName = () => {
    if (currentSeedType === "audio") {
      return css.seed_type_records_list_wrapper;
    }
    if (mobile) {
      return css.mobile_record_bar_wrapper;
    }
    return css.record_bar_wrapper;
  };

  return (
    <div className={getRecordBarWrapperClassName()}>
      <div
        className={
          currentSeedType === "audio"
            ? css.seed_type_records_list_wrapper
            : css.records_list_wrapper
        }
      >
        <RecordsList />
      </div>
      <div
        className={
          currentSeedType === "audio"
            ? css.seed_type_recording_button_wrapper
            : css.recording_button_wrapper
        }
      >
        <RecordControls recorderState={recorderState} handlers={handlers} />
      </div>
    </div>
  );
};
