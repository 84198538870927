import { calculatePercentage } from "util/math_util";
import { ISkillsCard, PROGRESSIONS_CARD } from "..";
import { Card } from "../card";

interface IProgressionCard extends ISkillsCard {
  progressionsCount: number;
  userProgressionsCount: number;
}
export const ProgressionCard = (props: IProgressionCard) => {
  const {
    active,
    isOtherActive,
    handleClickOpen,
    handleClickClose,
    children,
    progressionsCount,
    userProgressionsCount,
    profileLinkText = "Manually Adjust Skills",
  } = props;

  return (
    <Card
      measurementValue={userProgressionsCount}
      progress={calculatePercentage(userProgressionsCount, progressionsCount)}
      active={active}
      handleClick={() => handleClickOpen(PROGRESSIONS_CARD)}
      handleClickClose={() => handleClickClose()}
      isOtherActive={isOtherActive}
      line
      text="Chord Progressions"
      profileLinkText={profileLinkText}
    >
      {children}
    </Card>
  );
};
