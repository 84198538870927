import { combineReducers } from "redux";
import { modalReducer } from "./modal/modal_slice";
import { filtersReducer } from "./filters/filters_slice";
import { formReducer } from "./form/form_slice";

const uiReducer = combineReducers({
  modal: modalReducer,
  filters: filtersReducer,
  form: formReducer,
});

export default uiReducer;
