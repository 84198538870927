import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrgManagementOrganizations } from "redux/org_management/org_management_selectors";
import { CreateOrg } from "components/create_org";
import { fetchOrganizations } from "../../redux/org_management/org_management_slice";
import css from "./org_management.module.scss";
import { OrganizationCard } from "./organization_card/organization_card";

export const OrgManagement = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrganizations());
  }, [dispatch]);

  const organizations = useSelector(getOrgManagementOrganizations);

  return (
    <section className={css.container}>
      <CreateOrg />
      <div className={css.organizations_container}>
        {organizations.map((org) => (
          <OrganizationCard key={org.id} org={org} />
        ))}
      </div>
    </section>
  );
};
