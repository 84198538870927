import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Drawer,
  MenuItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MenuIcon from "@material-ui/icons/Menu";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import {
  setMobileSidebarContent,
  toggleMobileSidebar,
} from "redux/songwriting/songwriting_slice";
import { setAddChordMethod } from "redux/songwriting_mobile/songwriting_mobile_slice";
import { StyledMenu } from "components/songwriting_mobile/styled_menu/styled_menu";
import { SONG_STRUCTURE_LIST } from "../songbuilder_sidebar/build_song_bar/song_structure_list/song_structure_list";
import { ButtonDisplay } from "./button_display/button_display";
import { RecordButtonDisplay } from "./record_button_display/record_button_display";
import { ChordAccordion } from "../songbuilder_sidebar/build_song_bar/chord_accordion/chord_accordion";
import { SongItemsGrid } from "../songbuilder_sidebar/song_items_grid/song_items_grid";

export const SongBuilderMobileFooter = () => {
  const dispatch = useDispatch();
  const [drawerState, setDrawerState] = useState(false);
  const [drawerContent, setDrawerContent] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuVerticalOffset = -75;

  const [currentChordSearch, setCurrentChordSearch] = useState<string>("");

  const showAudioSidebar = () => {
    dispatch(setMobileSidebarContent("Audio"));
    dispatch(toggleMobileSidebar());
  };

  const showNotesSidebar = () => {
    dispatch(setMobileSidebarContent("Notes"));
    dispatch(toggleMobileSidebar());
  };

  const useStyles = makeStyles({
    paper: {
      background: "#F8F9FA",
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
      borderBottomRightRadius: "0px",
      borderBottomLeftRadius: "0px",
      height: "400px",
    },
  });

  const classes = useStyles();

  const handleClickAddMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAddMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      position="fixed"
      bottom="0"
      width="90vw"
      height="57px"
      margin="0 5vw 20px 5vw"
      borderRadius="16px"
      style={{ backgroundColor: "#2C2C2C", color: "#fff", gap: "10px" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Button onClick={handleClickAddMenu}>
        <ButtonDisplay Icon={AddIcon} text="Add" />
      </Button>
      <Button onClick={showNotesSidebar}>
        <ButtonDisplay Icon={MenuIcon} text="Notes" />
      </Button>
      <Button onClick={showAudioSidebar}>
        <ButtonDisplay Icon={PlayCircleOutlineIcon} text="Audio" />
      </Button>
      <RecordButtonDisplay onRecordComplete={showAudioSidebar} />

      <StyledMenu
        id="addMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseAddMenu}
        vertical={menuVerticalOffset}
      >
        <MenuItem
          onClick={() => {
            handleCloseAddMenu();
            setDrawerState(!drawerState);
            setDrawerContent("structure");
          }}
        >
          <Box display="flex" style={{ gap: "10px" }}>
            <Typography>Song Structure</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseAddMenu();
            setDrawerState(!drawerState);
            setDrawerContent("chord");
            dispatch(setAddChordMethod("footer"));
          }}
        >
          <Box display="flex" style={{ gap: "10px" }}>
            <Typography>Chords</Typography>
          </Box>
        </MenuItem>
      </StyledMenu>

      <Drawer
        anchor="bottom"
        open={drawerState}
        onClose={() => {
          setDrawerState(false);
        }}
        PaperProps={{ square: false }}
        classes={{ paper: classes.paper }}
      >
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          paddingTop="20px"
        >
          <div />
          <Typography
            style={{
              fontFamily: "Raleway",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "24px",
            }}
          >
            {drawerContent === "structure"
              ? "Insert Song Structure"
              : "Insert Chord"}
          </Typography>
          <Button
            onClick={() => {
              setDrawerState(false);
            }}
            style={{ fontWeight: 700, fontSize: "18px", color: "#76B3F3" }}
          >
            Close
          </Button>
        </Box>

        {drawerContent === "structure" ? (
          <Box padding="20px">
            <SongItemsGrid
              content={SONG_STRUCTURE_LIST.map((item) => ({
                ...item,
                draggable: false,
              }))}
              tipText=""
              closeMenuHandler={() => {
                setDrawerState(false);
              }}
            />
          </Box>
        ) : (
          <Box padding="20px">
            <ChordAccordion
              content={[]}
              setCurrentChordSearch={setCurrentChordSearch}
              currentChordSearch={currentChordSearch}
              closeMenuHandler={() => {
                setDrawerState(false);
              }}
            />
          </Box>
        )}
      </Drawer>
    </Box>
  );
};
