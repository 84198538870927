import { useState } from "react";
import { Box, Fab, IconButton, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserSelector } from "redux/auth/user_selectors";
import {
  createSongSeed,
  setCurrentSeedType,
  setNewSeedFlag,
} from "redux/song_seeds/song_seeds_slice";
import { openSeedNoteModal } from "redux/ui/modal/modal_slice";
import { SeedCards } from "../seed_cards/seed_cards";
import { SeedMenu } from "../seed_menu/seed_menu";

export const LyricIdeas = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUserSelector);

  const [seedAnchorEl, setSeedAnchorEl] = useState<null | HTMLElement>(null);

  const goBack = () => {
    dispatch(setCurrentSeedType({ type: null }));
    history.push("/");
  };

  const createNewSongSeed = () => {
    user && dispatch(createSongSeed({ seed_type: "lyric", user_id: user.id }));
    dispatch(setNewSeedFlag({ newSeed: true }));
    dispatch(openSeedNoteModal());
  };

  return (
    <Box
      width="90vw"
      display="flex"
      flexDirection="column"
      alignItems="center"
      margin="auto"
      paddingTop={1}
    >
      <Box width="100%">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={goBack}
          style={{ cursor: "pointer" }}
        >
          <IconButton aria-label="back">
            <ArrowBackIcon />
          </IconButton>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "#6b6868",
              fontFamily: "Raleway",
            }}
          >
            My Seeds
          </Typography>
        </Box>
      </Box>
      <Typography
        style={{
          fontFamily: "Raleway",
          fontWeight: 700,
          fontSize: 24,
          color: "#190067",
        }}
      >
        Lyric Ideas
      </Typography>
      <Typography
        style={{
          fontFamily: "Raleway",
          fontWeight: 500,
          fontStyle: "italic",
          fontSize: 16,
          color: "#5F6367",
        }}
      >
        Your list of lyric ideas
      </Typography>
      <SeedCards type="lyrics" setSeedAnchorEl={setSeedAnchorEl} />
      <Fab
        size="large"
        color="primary"
        style={{
          position: "fixed",
          bottom: 20,
          right: 20,
        }}
        onClick={() => {
          createNewSongSeed();
        }}
      >
        <AddIcon />
      </Fab>
      <SeedMenu seedAnchorEl={seedAnchorEl} setSeedAnchorEl={setSeedAnchorEl} />
    </Box>
  );
};
