import axios from "axios";
import { getNodeApiBaseUrl } from "api/base_api";
import { IStudentWithSkills } from "types/models/student_with_skills";

export interface IOrganization {
  id: number;
  name: string;
  logo_url: string;
  password_toggle: boolean;
  password: string;
  max_seats: number;
  recordings: boolean;
  studentsCount: number;
  tab?: boolean;
  staff?: boolean;
}

export interface IHiddenSongs {
  id: number;
  song_name: string;
  artist: string;
  visibility: boolean;
}
export interface IHiddenSongsApi {
  id: number;
  song_name: string;
  artist: string;
}

const MAX_SEATS_DEFAULT_VALUE = 0;

export const organizationApi = {
  createOrganization(
    email: string,
    orgName: string,
    max_seats: number = MAX_SEATS_DEFAULT_VALUE,
  ) {
    const url = `${getNodeApiBaseUrl()}/organizations`;
    return axios.post<{ id: string; email: string; name: string }>(url, {
      email,
      orgName,
      max_seats,
    });
  },
  fetchUserOrganizations(userId: number) {
    const url = `${getNodeApiBaseUrl()}/organizations/users/${userId}`;
    return axios.get<{ organizations: IOrganization[] }>(url);
  },
  removeStudent(studentId: number, organizationId: number, courseId: number) {
    const url = `${getNodeApiBaseUrl()}/organizations/${organizationId}/users/${studentId}`;
    return axios.delete(url, { params: { courseId } });
  },
  updateLogo(orgLogoURL: string, orgId: number) {
    const url = `${getNodeApiBaseUrl()}/organizations/${orgId}`;
    return axios.patch(url, { orgLogoURL });
  },
  searchStudents(orgId: number, studentName: string) {
    const url = `${getNodeApiBaseUrl()}/organizations/${orgId}/users`;
    return axios.get<{ usersWithSkills: IStudentWithSkills[] }>(url, {
      params: { studentName },
    });
  },
  changeGlobalPasswordToggle(orgId: number, passwordToggle: boolean) {
    const url = `${getNodeApiBaseUrl()}/organizations/${orgId}`;
    return axios.patch(url, { passwordToggle });
  },
  changeGlobalPassword(orgId: number, password: string) {
    const url = `${getNodeApiBaseUrl()}/organizations/${orgId}`;
    return axios.patch(url, { password });
  },
  changeTabStaffStatus(orgId?: number, staff?: boolean, tab?: boolean) {
    const url = `${getNodeApiBaseUrl()}/organizations/${orgId}`;
    return axios.patch(url, { staff, tab });
  },
  changeRecordingsToggle(orgId: number, recordings: boolean) {
    const url = `${getNodeApiBaseUrl()}/organizations/${orgId}`;
    return axios.patch(url, { recordings });
  },
  getHiddenSongs() {
    const url = `${getNodeApiBaseUrl()}/organizations/hiddenSongs`;
    return axios.get<IHiddenSongsApi[]>(url);
  },
  deleteHiddenSongs(songId: number) {
    const url = `${getNodeApiBaseUrl()}/organizations/hiddenSongs`;
    return axios.delete(url, { params: { songId } });
  },
  addHiddenSongs(songId: number) {
    const url = `${getNodeApiBaseUrl()}/organizations/hiddenSongs`;
    return axios.post(url, { songId });
  },
  searchSongByNameToHide(search: string) {
    const url = `${getNodeApiBaseUrl()}/organizations/searchSongsToHide`;
    return axios.get<IHiddenSongsApi[]>(url, { params: { search } });
  },
  upgradeStudents(orgId: number) {
    const url = `${getNodeApiBaseUrl()}/organizations/upgradeStudents`;
    return axios.post(url, { orgId });
  },
};
