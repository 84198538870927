import cn from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentCustomSong } from "redux/songwriting/songwriting_selectors";
import {
  updateCustomSong,
  updateCustomSelectedSong,
} from "redux/songwriting/songwriting_slice";
import * as Tone from "tone";
import css from "../../custom_song_settings.module.scss";
import { SongTempoPopover } from "./song_tempo_popover";

export type tempoProps = {
  setPlaying: (e: any) => void;
  counterRef: { current: any };
  loop?: Tone.Loop;
};

export const SongTempo = ({ setPlaying, counterRef, loop }: tempoProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const dispatch = useDispatch();
  const currentSong = useSelector(getCurrentCustomSong);

  const [sliderValue, setSliderValue] = useState<number>(currentSong.tempo);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setSliderValue(currentSong.tempo);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (sliderValue !== currentSong.tempo) {
      dispatch(updateCustomSong({ tempo: sliderValue }));
      dispatch(updateCustomSelectedSong({ tempo: sliderValue }));
    }
    if (loop && loop.state === "started") {
      loop.stop();
      counterRef.current = 0;
      setPlaying(false);
    }
  };

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onClick={handleOpen}
        className={cn(css.padding, css.div_button)}
      >
        <div className={css.main_font_styles}>{sliderValue}</div>
        <p className={css.subtitle_font_styles}>Tempo</p>
      </div>
      <SongTempoPopover
        anchorEl={anchorEl}
        handleClose={handleClose}
        sliderValue={sliderValue}
        setSliderValue={setSliderValue}
      />
    </>
  );
};
