import { getNodeApiBaseUrl } from "api/base_api";
import axios from "axios";
import { Subset } from "types/redux_";
import { IAudioSeed } from "redux/song_seeds/song_seed_interfaces";
import {
  ICustomSong,
  ISharedSong,
  ISongwriting,
} from "./songwriting_interfaces";

interface IShareSongByEmails {
  song_id: number;
  sender_id: number;
  recipient_emails: string[];
}

export const songwritingApi = {
  fetchUsersCustomSongs(userId: number) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/user_songs/`;
    return axios.get<ISongwriting[]>(url, {
      params: { user_id: userId },
    });
  },

  fetchOrgCustomSongs(orgId: number, excludeUserId: number) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/org_songs`;
    const result = axios.get(url, {
      params: { org_id: orgId, exclude_user_id: excludeUserId },
    });
    return result;
  },

  shareSongByEmails(payload: IShareSongByEmails) {
    const url = `${getNodeApiBaseUrl()}/shared-custom-songs/share-by-emails`;
    return axios.post<ISongwriting[]>(url, { payload });
  },

  fetchUserSharedSongs(userEmail: string) {
    const url = `${getNodeApiBaseUrl()}/shared-custom-songs`;
    return axios.get<ISharedSong[]>(url, {
      params: { recipient_email: userEmail },
    });
  },

  fetchCustomSong(songId: number) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/song/`;
    return axios.get<ISongwriting>(url, { params: { id: songId } });
  },

  createCustomSong(data: ICustomSong) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/`;
    return axios.post<ISongwriting>(url, data);
  },

  updateCustomSong(songId: number, data: Subset<ICustomSong>) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/song`;
    return axios.patch<ISongwriting>(url, data, { params: { id: songId } });
  },

  updateCustomSongUnitLyricsById(unitId: number, lyrics: string) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/song/unit/lyrics`;
    return axios.patch<ISongwriting>(
      url,
      { lyrics },
      { params: { id: unitId } },
    );
  },

  updateCustomSongUnitChordsById(unitId: number, chords: string) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/song/unit/chords`;
    return axios.patch<ISongwriting>(
      url,
      { chords },
      { params: { id: unitId } },
    );
  },

  updateCustomSongUnitOrder(
    songId: number,
    data: {
      order_data: { position: number; song_structure_id: number }[];
      edited_at: Date;
    },
  ) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/song_unit_order`;
    return axios.patch<ISongwriting>(url, data, { params: { id: songId } });
  },

  deleteCustomSong(songId: number) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/song`;
    return axios.delete(url, { params: { id: songId } });
  },

  deleteInvitee(email: string, shared_song_id: number) {
    const url = `${getNodeApiBaseUrl()}/shared-custom-songs/invitees`;
    return axios.delete(url, {
      params: { recipient_email: email, shared_song_id },
    });
  },

  deleteCustomSongUnit(
    songId: number,
    deleteInfo: { unit_id: number; edited_at: Date },
  ) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/delete_song_unit`;
    return axios.post(url, deleteInfo, { params: { id: songId } });
  },

  acceptInvitation(invitationToken: string) {
    const url = `${getNodeApiBaseUrl()}/shared-custom-songs/accept_invitation`;
    return axios.post(url, { invitationToken });
  },

  addAssetToCustomSong(
    songId: number,
    assetData: { location: string; rec_minutes: number; rec_seconds: number },
  ) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/add_song_asset`;
    return axios.post(url, assetData, { params: { id: songId } });
  },

  changeAssetTitle(songAssetId: number, assetTitle: string) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/change_song_asset_title`;
    return axios.patch(
      url,
      { title: assetTitle },
      { params: { id: songAssetId } },
    );
  },

  deleteCustomSongAsset(songId: number, assetUrl: string) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/delete_song_asset/`;
    return axios.delete(url, { params: { id: songId }, data: { assetUrl } });
  },

  addAudioSeed(audioData: {
    location: string;
    rec_minutes: number;
    rec_seconds: number;
    user_id: number;
  }) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/add_audio_seed`;
    return axios.post(url, audioData);
  },

  fetchAudioSeeds(userId: number) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/user_audio_seeds/`;
    return axios.get<IAudioSeed[]>(url, {
      params: { user_id: userId },
    });
  },

  deleteAudioSeed(audioUrl: string) {
    const url = `${getNodeApiBaseUrl()}/custom-songs/delete_audio_seed/`;
    return axios.delete(url, { data: { audioUrl } });
  },

  updateSharedSong(data: {
    email: string;
    url_token?: string;
    viewedAt?: Date;
    editedAt?: Date;
  }) {
    const url = `${getNodeApiBaseUrl()}/shared-custom-songs`;
    return axios.patch(url, data);
  },

  fetchSongTip() {
    const url = `${getNodeApiBaseUrl()}/custom-songs/get_song_tip`;
    return axios.get(url);
  },

  fetchCustomSongTemplates() {
    const url = `${getNodeApiBaseUrl()}/custom-songs/templates`;
    return axios.get<ISongwriting[]>(url);
  },

  fetchCustomSongCertificationNumber() {
    const url = `${getNodeApiBaseUrl()}/custom-songs/generate_certification_number`;
    return axios.post(url);
  },
};
