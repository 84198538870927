import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isButtonDisabledSelector } from "redux/ui/form/form_selectors";
import { getModalDataSelector } from "redux/ui/modal/modal_selectors";
import { getErrors } from "redux/errors/errors_selectors";
import { userApi } from "api/user_api";
import { handleLogin } from "redux/auth/auth_slice";
import Button from "components/reusable/buttons/button";
import { openReqPWResetModal } from "redux/ui/modal/modal_slice";
import {
  clearErrors,
  receiveErrors,
} from "redux/errors/session/session_errors_actions";

import css from "../login_modal.module.scss";

export const Form = () => {
  const dispatch = useDispatch();

  const [loginCredentials, setLoginCredentials] = useState<{
    [key: string]: string;
  }>({
    email: "",
    password: "",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const foundUser = await userApi.fetchUserFromEmail(loginCredentials.email);
    const OAuthProvider = foundUser.data.provider;

    if (OAuthProvider === "email") {
      dispatch(
        handleLogin(loginCredentials as { email: string; password: string }),
      );
    } else {
      let providerName;
      switch (OAuthProvider) {
        case "google_oauth2":
          providerName = "Google";
          break;
        case "microsoft_oauth2":
          providerName = "Microsoft";
          break;
        default:
          providerName = "Unknown";
      }

      dispatch(
        receiveErrors({
          label: "login",
          errors: [
            `You previously logged in via ${providerName} SSO.  Please Log In with ${providerName} below.  Still need help?  Email help@moosiko.com`,
          ],
        }),
      );
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setLoginCredentials({ ...loginCredentials, [id]: value });
  };

  const { url } = useSelector(getModalDataSelector);
  const errors = useSelector(getErrors).session.login;
  const disabled = useSelector(isButtonDisabledSelector);

  let loginErr;
  if (errors) {
    [loginErr] = errors;
  }

  useEffect(() => {
    return () => {
      if (errors) {
        dispatch(clearErrors());
      }
    };
  }, [dispatch, errors]);

  return (
    <form onSubmit={handleSubmit} className={css.form}>
      <input
        onChange={handleChange}
        className={`${css.input} ${loginErr ? css.email_error : null}`}
        value={loginCredentials.email}
        id="email"
        type="email"
        placeholder="Email"
        autoComplete="email"
      />
      <input
        onChange={handleChange}
        className={`${css.input} ${loginErr ? css.input_error : null}`}
        value={loginCredentials.password}
        id="password"
        type="password"
        placeholder="Password"
        autoComplete="current-password"
      />
      <div className={loginErr ? `${css.error} fadeIn animated2` : ""}>
        {loginErr}
      </div>
      <div className={css.wrapper__link}>
        <div
          role="link"
          tabIndex={0}
          className={css.link}
          style={{ padding: "5px 0", marginTop: "10px" }}
          onClick={() => dispatch(openReqPWResetModal(url))}
        >
          Forgot Password?
        </div>
      </div>
      <Button showLoader={disabled} classes={{ btn: css.button__login }}>
        Log In
      </Button>
      <div className={css.divider__wrapper}>
        <span className={css.test}>OR</span>
      </div>
    </form>
  );
};
