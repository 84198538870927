import { Popover, Slider, Typography } from "@material-ui/core";
import React from "react";

import css from "../../custom_song_settings.module.scss";

interface ISongTempoPopoverProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  sliderValue: Number;
  setSliderValue: (_value: number) => void;
}

export const SongTempoPopover: React.FC<ISongTempoPopoverProps> = ({
  anchorEl,
  handleClose,
  sliderValue,
  setSliderValue,
}) => {
  const openPopover = !!anchorEl;
  const id = openPopover ? "song-key-popover" : undefined;

  const sliderChangeHandler = (event: any, newValue: number | number[]) => {
    setSliderValue(newValue as number);
  };

  return (
    <Popover
      PaperProps={{ className: css.popover }}
      id={id}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Typography>Tempo</Typography>
      <Slider
        value={Number(sliderValue)}
        onChange={sliderChangeHandler}
        min={40}
        max={220}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
      />
    </Popover>
  );
};
