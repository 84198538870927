import { createSelector } from "reselect";
import { IState } from "redux/store";
import { PERMISSIONS } from "redux/auth/permissions/permissions_consts";

export const getSongwriting = (state: IState) => state.songwriting;

export const getCustomSongs = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.customSongs,
);
export const getCustomSongId = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.selectedSongId,
);
export const getCurrentCustomSong = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.customSelectedSong,
);

export const getSelectedSongBucket = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.selectedSongBucket,
);

export const getActiveTab = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.activeTab,
);

export const getIsDraggedChord = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.isDraggedChord,
);

export const getTipsList = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.tipsList,
);

export const getCustomSongTemplates = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.customSongTemplates,
);

export const getCustomSongTemplate = (id?: number) =>
  createSelector([getSongwriting], (songwriting) => {
    const customSongTemplate = songwriting.customSongTemplates.find((item) => {
      return item.id === id;
    });
    if (customSongTemplate) {
      return customSongTemplate;
    }
  });

export const getCustomSongTipNote = (id: number) =>
  createSelector([getSongwriting], (songwriting) => {
    const customSong = songwriting.customSongs.find((item) => {
      return item.id === id;
    });
    if (customSong) {
      return customSong.note;
    }
  });

export const getCollapsedStructures = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.collapsedStructures,
);

export const getDraggableItem = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.draggableItem,
);

export const getTablesData = () =>
  createSelector([getSongwriting], (songwriting) => songwriting.tableData);

export const getTableData = (id: number) =>
  createSelector([getSongwriting], (songwriting) =>
    songwriting.tableData.find((item) => item.id === id),
  );

export const getCurrentCustomSongUnit = (id: number) =>
  createSelector([getSongwriting], (songwriting) =>
    songwriting.customSelectedSong.custom_song_lyrics.find(
      (item) => item.id === id,
    ),
  );

export const getCurrentCustomSongLyrics = (id: number) =>
  createSelector(
    [getSongwriting],
    (songwriting) =>
      songwriting.customSelectedSong.custom_song_lyrics.find(
        (item) => item.id === id,
      )?.song_lyrics,
  );

export const getActualTableData = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.actualTableData,
);

export const getActualTableDataById = (id: number) =>
  createSelector(
    [getSongwriting],
    (songwriting) =>
      songwriting.actualTableData.find((item) => item.id === id)?.data,
  );

export const getIdSongStructureWhenReplaceChordInList = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.idSongStructureWhenReplaceChordInList,
);

export const getCountOfInputsAfterDeleteRow = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.countOfInputsAfterDeleteRow,
);

export const getTypeOfDragAndDropAction = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.typeOfDragAndDropAction,
);

export const getActualTablesData = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.actualTableData,
);

export const getReplacedSongUnit = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.replacedSongUnit,
);

export const getIdOfStructureToFocus = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.idOfStructureToFocus,
);

export const getSharedWithMeSongs = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.sharedWithMeSong,
);

export const getOrgSongwritingSongs = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.orgSongs,
);

export const getOrgSongwritingSongById = (id: number | null) =>
  createSelector([getSongwriting], (songwriting) =>
    songwriting.orgSongs.find((item) => item.id === id),
  );

export const getActiveOrgSongId = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.activeOrgSongId,
);

export const getMobileSidebarState = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.mobileSidebar.show,
);

export const getMobileSidebarContent = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.mobileSidebar.content,
);

export const getUsingMobileAppFlag = createSelector(
  [getSongwriting],
  (songwriting) => songwriting.usingMobileAppFlag,
);

export const getCustomSongById = (id?: number) =>
  createSelector([getSongwriting], (songwriting) => {
    if (id) {
      return songwriting.customSongs.find((item) => {
        return item.id === id;
      });
    }
    return null;
  });

export const isUnlimitedSongwritingUser = createSelector(
  [(state: IState) => state.auth.permissions],
  (userPermissions) => {
    return userPermissions.includes(PERMISSIONS.UNLIMITED_SONGWRITING);
  },
);
