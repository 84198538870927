import { useDispatch, useSelector } from "react-redux";
/* eslint-disable no-magic-numbers */
import { uploadBlobToS3 } from "api/s3_api";
import { useEffect, useState } from "react";
import { addAssetToCustomSong } from "redux/songwriting/songwriting_slice";
import { addAudioSeed } from "redux/song_seeds/song_seeds_slice";
import { getCurrentSeedType } from "redux/song_seeds/song_seed_selector";
import { getUserIdSelector } from "redux/auth/user_selectors";

import { FFmpeg } from "@ffmpeg/ffmpeg";
import { S3AudioRecord } from "../api/s3_api";
import {
  AudioTrack,
  Interval,
  MediaRecorderEvent,
  Recorder,
  SetRecorder,
} from "../types/recorder";

const initialState: Recorder = {
  recordingMinutes: 0,
  recordingSeconds: 0,
  initRecording: false,
  mediaStream: null,
  mediaRecorder: null,
  audio: null,
};

export async function startRecording(setRecorderState: SetRecorder) {
  try {
    const stream: MediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    setRecorderState((prevState) => {
      return {
        ...prevState,
        recordingMinutes: 0,
        recordingSeconds: 0,
        mediaStream: stream,
      };
    });
  } catch (err) {
    /* eslint-disable-next-line no-console */
    console.error(err);
  }
}

export function saveRecording(recorder: any) {
  if (recorder.state !== "inactive") {
    recorder.stop();
  }
}

export default function useRecorder() {
  const dispatch = useDispatch();

  const currentSeedType = useSelector(getCurrentSeedType);
  const userId = useSelector(getUserIdSelector);

  const [recorderState, setRecorderState] = useState<Recorder>(initialState);
  const [blob, setBlob] = useState<Blob | null>(null);
  const [increment, setIncrement] = useState<number>(0);
  useEffect(() => {
    const MAX_RECORDER_TIME = 10;
    let recordingInterval: Interval = null;

    if (recorderState.initRecording) {
      recordingInterval = setInterval(() => {
        setIncrement(increment + 1);
        if (increment % 10 === 0) {
          setRecorderState((prevState: Recorder) => {
            if (
              prevState.recordingMinutes === MAX_RECORDER_TIME &&
              prevState.recordingSeconds === 0
            ) {
              typeof recordingInterval === "number" &&
                clearInterval(recordingInterval);
              return prevState;
            }

            if (
              prevState.recordingSeconds >= 0 &&
              prevState.recordingSeconds < 59
            ) {
              return {
                ...prevState,
                recordingSeconds: prevState.recordingSeconds + 1,
              };
            }
            if (prevState.recordingSeconds === 59) {
              return {
                ...prevState,
                recordingMinutes: prevState.recordingMinutes + 1,
                recordingSeconds: 0,
              };
            }
            return prevState;
          });
        }
      }, 100);
    } else {
      typeof recordingInterval === "number" && clearInterval(recordingInterval);
    }

    return () => {
      typeof recordingInterval === "number" && clearInterval(recordingInterval);
    };
  });

  useEffect(() => {
    setRecorderState((prevState) => {
      if (prevState.mediaStream) {
        return {
          ...prevState,
          mediaRecorder: new MediaRecorder(prevState.mediaStream),
        };
      }
      return prevState;
    });
  }, [recorderState.mediaStream]);

  useEffect(() => {
    const recorder = recorderState.mediaRecorder;
    const ffmpeg = new FFmpeg();
    let chunks: Blob[] = [];

    if (recorder && recorder.state === "inactive") {
      recorder.start();

      recorder.ondataavailable = (e: MediaRecorderEvent) => {
        chunks.push(e.data);
      };

      recorder.onstop = () => {
        convertAudioToMp3(new Blob(chunks, { type: "audio/webm" }), ffmpeg);
        chunks = [];
        recorder.stream.getAudioTracks().forEach((track: AudioTrack) => {
          track.stop();
        });
        setRecorderState((prevState) => {
          return {
            ...prevState,
            initRecording: false,
          };
        });
      };

      recorder.onstart = (e) => {
        setRecorderState((prevState) => {
          return {
            ...prevState,
            initRecording: true,
          };
        });
      };
    }

    const convertAudioToMp3 = async (audioBlob: Blob, ffmpeg: FFmpeg) => {
      await ffmpeg.load();

      const arrayBuffer = await audioBlob.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);

      ffmpeg.writeFile("input", uint8Array);
      await ffmpeg.exec(["-i", "input", "output.mp3"]);
      const output = await ffmpeg.readFile("output.mp3");
      const mp3Blob = new Blob([output], { type: "audio/mp3" });
      setBlob(mp3Blob);
    };

    return () => {
      if (recorder) {
        recorder.stream
          .getAudioTracks()
          .forEach((track: AudioTrack) => track.stop());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, recorderState.mediaRecorder]);

  useEffect(() => {
    if (blob !== null && recorderState.recordingSeconds > 0) {
      uploadBlobToS3(blob, S3AudioRecord, "New-recording").then(
        ({ location }) => {
          if (currentSeedType !== null) {
            if (userId) {
              dispatch(
                addAudioSeed({
                  location,
                  rec_seconds: recorderState.recordingSeconds,
                  rec_minutes: recorderState.recordingMinutes,
                  user_id: userId,
                }),
              );
            }
          } else {
            dispatch(
              addAssetToCustomSong({
                location,
                rec_seconds: recorderState.recordingSeconds,
                rec_minutes: recorderState.recordingMinutes,
              }),
            );
          }
        },
      );

      setRecorderState((prevState: Recorder) => {
        if (prevState.mediaRecorder) {
          return {
            ...initialState,
            audio: window.URL.createObjectURL(blob),
          };
        }
        return initialState;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blob]);

  return {
    recorderState,
    startRecording: () => startRecording(setRecorderState),
    cancelRecording: () => setRecorderState(initialState),
    saveRecording: () => saveRecording(recorderState.mediaRecorder),
  };
}
