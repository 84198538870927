import { useCallback, useEffect, useRef, useState } from "react";

import { getRoleSelector } from "redux/auth/roles/roles_selector";
import { ROLES } from "redux/auth/roles/roles_consts";
import { PlayCircleFilled, PauseCircleFilled } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { DEV } from "util/vars";
import { getCurrentCustomSong } from "redux/songwriting/songwriting_selectors";
import * as Tone from "tone";
import { SongBackingTrack } from "./song_metadata/song_backing_track/song_backing_track";
import { SongKey } from "./song_metadata/song_key/song_key";
import { SongMeter } from "./song_metadata/song_meter/song_meter";
import { SongTempo } from "./song_metadata/song_tempo/song_tempo";
import css from "./custom_song_settings.module.scss";
import GenerateVoice from "../songbuilder_generate_voice";
import hiHatHit from "./song_metadata/song_backing_track/song_backing_track_hits_assets/HHats-CL-V09.wav";
import openHiHatHit from "./song_metadata/song_backing_track/song_backing_track_hits_assets/HHats-OP-V05.wav";
import kickHit from "./song_metadata/song_backing_track/song_backing_track_hits_assets/Kick-V05.wav";
import snareHit from "./song_metadata/song_backing_track/song_backing_track_hits_assets/RD_S_9.wav";
import rideHit from "./song_metadata/song_backing_track/song_backing_track_hits_assets/Ride-V03.wav";
import crashHit from "./song_metadata/song_backing_track/song_backing_track_hits_assets/Crash-V01.wav";
import highTomHit from "./song_metadata/song_backing_track/song_backing_track_hits_assets/TOM10-V07.wav";
import highTomFlam from "./song_metadata/song_backing_track/song_backing_track_hits_assets/FLAM10-V04.wav";
import floorTomHit from "./song_metadata/song_backing_track/song_backing_track_hits_assets/lowTom.wav";
import midTomHit from "./song_metadata/song_backing_track/song_backing_track_hits_assets/TOM10-V05-StarClassic-10x10.wav";
import shakerHit from "./song_metadata/song_backing_track/song_backing_track_hits_assets/RD_P_ES_6.wav";
import tambourineHit from "./song_metadata/song_backing_track/song_backing_track_hits_assets/Tambourine_01_728.wav";

export const CustomSongSettings = () => {
  interface DrumSetRef {
    hh: Tone.Sampler;
    oh: Tone.Sampler;
    sn: Tone.Sampler;
    kd: Tone.Sampler;
    rc: Tone.Sampler;
    cc: Tone.Sampler;
    ft: Tone.Sampler;
    ht: Tone.Sampler;
    htf: Tone.Sampler;
    mt: Tone.Sampler;
    sh: Tone.Sampler;
    tm: Tone.Sampler;
  }

  const role = useSelector(getRoleSelector);
  const magHalf: number = 0.5;
  const mag1: number = 1;
  const mag2: number = 2;
  const mag3: number = 3;
  const mag4: number = 4;
  const mag32: number = 32;
  const currentSong = useSelector(getCurrentCustomSong);
  const [playing, setPlaying] = useState(false);
  const [loop, setLoop] = useState<Tone.Loop | undefined>(undefined);
  const [backBeat, setBackBeat] = useState("Pop");
  const backbeatRef = useRef("Pop");
  const counterRef = useRef<number>(0);
  const drumSetRef = useRef<DrumSetRef | undefined>({
    hh: new Tone.Sampler({
      urls: { C2: hiHatHit },
      volume: -3,
    }).toDestination(),
    oh: new Tone.Sampler({
      urls: { C2: openHiHatHit },
      volume: -5,
    }).toDestination(),
    sn: new Tone.Sampler({
      urls: { C2: snareHit },
      volume: -7,
    }).chain(
      new Tone.Reverb({
        decay: mag2,
        wet: 0.5,
      }),
      Tone.Destination,
    ),
    tm: new Tone.Sampler({
      urls: { C2: tambourineHit },
      volume: -7,
    }).toDestination(),
    kd: new Tone.Sampler({
      urls: { C2: kickHit },
      volume: 4,
    }).chain(
      new Tone.Reverb({
        decay: mag1,
        wet: 0.5,
      }),
      Tone.Destination,
    ),
    rc: new Tone.Sampler({
      urls: { C2: rideHit },
      volume: 3,
    }).toDestination(),
    cc: new Tone.Sampler({
      urls: { C2: crashHit },
    }).toDestination(),
    ft: new Tone.Sampler({
      urls: { C2: floorTomHit },
      volume: -10,
    }).toDestination(),
    ht: new Tone.Sampler({
      urls: { C2: highTomHit },
      volume: -10,
    }).toDestination(),
    htf: new Tone.Sampler({
      urls: { C2: highTomFlam },
      volume: -10,
    }).toDestination(),
    mt: new Tone.Sampler({
      urls: { C2: midTomHit },
      volume: -5,
    }).toDestination(),
    sh: new Tone.Sampler({
      urls: { C2: shakerHit },
      volume: -12,
    }).toDestination(),
  });
  const drumRef = useRef({
    pop: {
      hh: [
        1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0,
        1, 0, 1, 0, 1, 0, 0, 0,
      ],
      kd: [
        1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0,
        1, 0, 0, 0, 0, 0, 0, 0,
      ],
      sn: [
        0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0,
        0, 0, 0, 0, 1, 0, 0, 0,
      ],
      oh: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 1, 0,
      ],
    },
    rock: {
      hh: [
        1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0,
        1, 0, 1, 0, 1, 0, 0, 0,
      ],
      kd: [
        1, 0, 1, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0,
        1, 0, 1, 0, 0, 0, 0, 0,
      ],
      sn: [
        0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0,
        0, 0, 0, 0, 1, 0, 0, 0,
      ],
      ft: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 1,
      ],
      ht: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 1, 0,
      ],
      oh: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 1, 0, 0,
      ],
    },
    blues: {
      rc: [
        1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1,
        1, 0, 0, 1, 1, 0, 0, 1,
      ],
      kd: [
        1, 0, 0, 1, 0, 0, 0, 1, 1, 0, 0, 1, 0, 0, 0, 1, 1, 0, 0, 1, 0, 0, 0, 1,
        1, 0, 0, 1, 0, 0, 0, 1,
      ],
      sn: [
        0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0,
        0, 0, 0, 0, 1, 0, 0, 0,
      ],
    },
    country: {
      hh: [
        0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0,
        0, 0, 1, 0, 0, 0, 1, 0,
      ],
      kd: [
        1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0,
        1, 0, 0, 0, 1, 0, 0, 0,
      ],
      sn: [
        0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0,
        0, 1, 1, 0, 0, 1, 1, 0,
      ],
    },
    folk: {
      sh: [
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1,
      ],
      kd: [
        1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0,
        1, 0, 0, 0, 1, 0, 0, 0,
      ],
      tm: [
        0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0,
        0, 0, 0, 0, 1, 0, 0, 0,
      ],
      ht: [
        0, 0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
      ],
      htf: [
        1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
      ],
      mt: [
        0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
      ],
      ft: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 1, 0, 0, 1,
        0, 1, 0, 0, 1, 0, 1, 1,
      ],
    },
  });

  const selectBeat = (e: any) => {
    const val: string = e.target.value;
    setBackBeat(val);
    if (loop) {
      loop.stop();
      setPlaying(false);
      counterRef.current = 0;
    }
    backbeatRef.current = val;
  };
  const toggleBeat = async () => {
    if (loop) {
      if (!playing) {
        loop.start();
      } else {
        loop.stop();
        counterRef.current = 0;
      }
    }
    setPlaying(!playing);
  };
  const play = useCallback((time: any) => {
    const currentBeat = backbeatRef.current.toLocaleLowerCase();
    if (currentBeat === "country" && drumSetRef.current) {
      drumSetRef.current.sn.volume.value = -20;
      drumSetRef.current.hh.volume.value = -10;
    } else if (currentBeat !== "country" && drumSetRef.current) {
      drumSetRef.current.sn.volume.value = -7;
      drumSetRef.current.hh.volume.value = -3;
    }
    Object.keys(drumRef.current[currentBeat]).forEach((key) => {
      drumRef.current[currentBeat][key].forEach((beat: number, i: number) => {
        if (drumSetRef.current && beat > 0 && i === counterRef.current) {
          if (currentBeat === "country") {
            if (key === "sn") {
              if (
                (i / mag4) % mag4 !== 0 &&
                (i / mag2) % mag2 !== 0 &&
                i !== 0
              ) {
                drumSetRef.current[key].volume.value = -20;
                drumSetRef.current[key].triggerAttackRelease(
                  "C2",
                  "8n",
                  time,
                  magHalf,
                );
              } else {
                drumSetRef.current[key].volume.value = -25;
                drumSetRef.current[key].triggerAttackRelease(
                  "C2",
                  "8n",
                  time,
                  magHalf * -1,
                );
              }
            }
          }
          if (currentBeat === "folk") {
            if (key === "sh") {
              if ((i + 1) % mag4 !== 0 && i !== 0) {
                drumSetRef.current[key].volume.value = -18;
                drumSetRef.current[key].triggerAttackRelease(
                  "C2",
                  "8n",
                  time,
                  magHalf,
                );
              } else {
                drumSetRef.current[key].volume.value = -20;
                drumSetRef.current[key].triggerAttackRelease(
                  "C2",
                  "8n",
                  time,
                  magHalf * -1,
                );
              }
            }
          }
          if (key === "cc") {
            drumSetRef.current[key].triggerAttackRelease("C2", "1m", time);
          }
          if (key === "oh") {
            if (currentBeat === "rock") {
              drumSetRef.current[key].triggerAttackRelease("C2", "4n", time);
            }
            drumSetRef.current[key].triggerAttackRelease("C2", "8n", time);
          } else {
            drumSetRef.current[key].triggerAttackRelease("C2", mag3, time);
          }
        }
      });
    });
  }, []);
  const playSong = useCallback(
    (time: any) => {
      play(time);
      counterRef.current = (counterRef.current + mag1) % mag32;
    },
    [play],
  );

  useEffect(() => {
    if (Tone.Transport.bpm.value !== Number(currentSong.tempo)) {
      Tone.Transport.stop();
      Tone.Transport.bpm.value = Number(currentSong.tempo);
      Tone.Transport.start();
    }
    if (loop === undefined) {
      setLoop(new Tone.Loop(playSong, "16n"));
      Tone.start();
      Tone.loaded();
      Tone.Transport.timeSignature = mag4 / mag4;
      Tone.Transport.start();
    }
    return () => {
      loop?.dispose();
      setPlaying(false);
      counterRef.current = 0;
    };
  }, [loop, playSong, currentSong.tempo]);
  return (
    <div className={css.settings}>
      <SongKey />
      <SongMeter />
      <SongTempo setPlaying={setPlaying} counterRef={counterRef} loop={loop} />
      {currentSong.time_signature === "4/4" ? (
        <>
          <SongBackingTrack selectedBeat={backBeat} selectBeat={selectBeat} />
          <button type="button" onClick={toggleBeat}>
            {playing ? (
              <PauseCircleFilled
                className={css.playButtonStyles}
                fontSize="large"
              />
            ) : (
              <PlayCircleFilled
                className={css.playButtonStyles}
                fontSize="large"
              />
            )}
          </button>
        </>
      ) : null}
      {(role === ROLES.ADMIN || DEV) && <GenerateVoice />}
    </div>
  );
};
