import React, { useState } from "react";
import cn from "classnames";
import { Popover, Typography } from "@material-ui/core";
import css from "../../custom_song_settings.module.scss";

const options = ["Pop", "Rock", "Blues", "Country", "Folk"];

export type backBeatProps = {
  selectBeat: (e: any) => void;
  selectedBeat: string;
};

export const SongBackingTrack = ({
  selectBeat,
  selectedBeat,
}: backBeatProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const openPopover = !!anchorEl;

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose: () => void = () => {
    setAnchorEl(null);
  };

  const handleSelect = (e: any) => {
    selectBeat(e);
    handleClose();
  };

  const id = openPopover ? "song-backbeat-popover" : undefined;

  const displayOptions = () => {
    return options.map((option) => {
      return (
        <li key={option} style={{ marginTop: ".75rem", width: "inherit" }}>
          <button
            onClick={handleSelect}
            className={css.backBeatButton}
            style={{ width: "100%" }}
            type="submit"
            value={option}
          >
            {option}
          </button>
        </li>
      );
    });
  };

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onClick={handleOpen}
        className={cn(css.padding, css.div_button)}
      >
        <div className={css.main_font_styles}>{selectedBeat}</div>
        <p className={css.subtitle_font_styles}>Backing Track</p>
      </div>
      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className={css.list_padding}>
          <Typography gutterBottom>Backing Tracks</Typography>
          <ul>{displayOptions()}</ul>
        </div>
      </Popover>
    </>
  );
};
