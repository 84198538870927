import React from "react";
import { Button, TextField, CircularProgress } from "@material-ui/core";
import { openAddStudentsModal } from "redux/ui/modal/modal_slice";
import CloseIcon from "@material-ui/icons/Close";
import {
  getCourseName,
  getFetchingStudent,
  getCoursesEntity,
} from "redux/courses/courses_selectors";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import css from "./course_top.module.scss";
import { CourseSummarizedInfo } from "../course_summarized_info";

interface ICourseTop {
  students: unknown[];
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchValue: string;
}

export const CUSTOM = "CUSTOM";

export const CourseTop = ({
  students,
  setSearchValue,
  searchValue,
}: ICourseTop) => {
  const courseId = Number(useParams<{ courseId: string }>().courseId);
  const dispatch = useDispatch();

  const { startDate, endDate } = useSelector(getCoursesEntity);
  const isFetching = useSelector(getFetchingStudent);
  const courseName = useSelector(getCourseName(courseId));
  // const courses = useSelector(getCourses);

  const clearSearch = () => {
    setSearchValue("");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
  };

  // useEffect(() => {
  //   const modifiedEndDate = endDate || moment();
  //   if (courses.length && courseId) {
  //     dispatch(
  //       fetchSummarizedStudentsData({
  //         courseId,
  //         dateRange: { startDate, endDate: modifiedEndDate },
  //       }),
  //     );
  //   }
  // }, [students, dispatch, courseId, courses.length, startDate, endDate]);

  let content = null;
  if (isFetching) {
    content = (
      <div className={css.loader_container}>
        <CircularProgress />
      </div>
    );
  } else if (students.length) {
    content = (
      <>
        <div className={css.search_container}>
          <TextField
            id="outlined-basic"
            label="Search..."
            onChange={handleChange}
            value={searchValue}
            variant="outlined"
            className={css.search_input}
          />
          {searchValue ? (
            <button
              onClick={clearSearch}
              className={css.cancel_search}
              type="button"
            >
              <CloseIcon style={{ height: 32, width: 32 }} />
            </button>
          ) : null}
        </div>
        {searchValue ? null : (
          <CourseSummarizedInfo
            startDate={startDate}
            endDate={endDate}
            courseId={courseId}
          />
        )}
      </>
    );
  }

  return (
    <div className={css.container}>
      <Button
        variant="contained"
        onClick={() => dispatch(openAddStudentsModal(courseId))}
        className={css.add_new_student}
      >
        Add Students
      </Button>
      <h1 className={css.h1}>{courseName}</h1>
      {content}
    </div>
  );
};
